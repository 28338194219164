import { AnimatePresence } from 'framer-motion';

import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { Banner, BannerBackground, BannerWrapper } from './RecordingBanner.styles';

interface RecordingBanner {
  message: string;
  open: boolean;
}

export function RecordingBanner({ message, open }: RecordingBanner) {
  const isMobile = useIsMobileViewport();
  const bannerHeight = isMobile ? '2.7rem' : '3.25rem';

  return (
    <AnimatePresence>
      {open ? (
        <BannerWrapper
          initial={{ height: 0 }}
          animate={{ height: bannerHeight, transition: { duration: 0.25, ease: 'easeOut' } }}
          exit={{ height: 0, transition: { duration: 0.25, ease: 'easeIn' } }}
        >
          <BannerBackground
            initial={{ height: 0 }}
            animate={{ height: bannerHeight, transition: { duration: 0.25, ease: 'easeOut' } }}
            exit={{ height: 0, transition: { duration: 0.25, ease: 'easeIn' } }}
          />
          <Banner
            initial={{ translateY: '-100%' }}
            animate={{ translateY: '0%', transition: { duration: 0.25, ease: 'easeOut' } }}
            exit={{ translateY: '-100%', transition: { duration: 0.25, ease: 'easeIn' } }}
          >
            <RMText type="sans" size={isMobile ? 'xs' : 's'} color="on-surface-primary" bold>
              {message}
            </RMText>
          </Banner>
        </BannerWrapper>
      ) : null}
    </AnimatePresence>
  );
}
