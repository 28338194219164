import { useCallback, useEffect, useMemo, useState } from 'react';

import { useInterviewManager } from '@/modules/conversation-recorder/interview';
import { useInterviewManagerTicker } from '@/modules/conversation-recorder/interview/interview-manager-ticker';

import { RecordingDuration } from '../components/RecordingDuration/RecordingDuration';

export function RecordingDurationContainer() {
  const interviewManager = useInterviewManager();

  const [duration, setDuration] = useState(0);
  const maxDuration = interviewManager.getMaxRecordingDuration();

  useInterviewManagerTicker(
    useCallback(() => {
      try {
        setDuration(interviewManager.getDuration());
      } catch (error) {
        console.log(error);
        // interview manager died
      }
    }, [interviewManager]),
  );

  useEffect(() => {
    try {
      setDuration(interviewManager.getDuration());
    } catch (error) {
      console.log(error);
      // interview manager died
    }
  }, [interviewManager]);

  const formatRecordingDuration = useCallback((rawDuration: number) => {
    const seconds = Math.round(rawDuration) % 60;
    const minutes = Math.floor((Math.round(rawDuration) % 3600) / 60);
    const hours = Math.floor(Math.round(rawDuration) / 3600);

    const formattedSeconds = String(Math.round(seconds)).padStart(2, '0');
    const formattedMinutes = String(Math.round(minutes)).padStart(2, '0');

    if (hours > 0) {
      const formattedHours = String(Math.round(hours)).padStart(2, '0');
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    return `${formattedMinutes}:${formattedSeconds}`;
  }, []);

  const formattedRecordingDuration = useMemo(
    () => formatRecordingDuration(duration),
    [duration, formatRecordingDuration],
  );

  const formattedMaxDuration = useMemo(
    () => formatRecordingDuration(maxDuration),
    [maxDuration, formatRecordingDuration],
  );

  return <RecordingDuration currentDuration={formattedRecordingDuration} maxDuration={formattedMaxDuration} />;
}
