import { styled } from '@linaria/react';

export const Content = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
`;

export const RecordingPromptWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
`;

export const WidgetWrapper = styled.div`
  display: flex;
  position: relative;
  /* Buttons height + padding + animation arrow height */
  height: calc(80px + var(--spacing-md) + calc((2.75rem + var(--spacing-sm))));
  width: 100%;
  overflow: hidden;
`;

export const AbsoluteWidget = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

export const ButtonWrapper = styled.div`
  width: 112px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
