import { ReactNode } from 'react';

import { RMText } from '@/components/RMText/RMText';

import { Image, ImageWrapper, Question, StyledRecordingPrompt } from './RecordingPrompt.styles';

export interface RecordingPromptProps {
  question: string;
  askedBy: string;
  photoSrc?: string | null;
  fullscreenEnabled?: boolean;
  Footer?: ReactNode;
}

export function RecordingPrompt({
  question,
  askedBy,
  photoSrc = null,
  fullscreenEnabled = true,
  Footer,
}: RecordingPromptProps) {
  return (
    <StyledRecordingPrompt>
      {photoSrc && (
        <ImageWrapper>
          <Image src={photoSrc} alt="prompt-photo" variant="light" fullscreenEnabled={fullscreenEnabled} />
        </ImageWrapper>
      )}
      <Question data-large={photoSrc === null}>
        <RMText type="sans" size="xs" bold color="primary-decorative">
          {askedBy} asked
        </RMText>
        <RMText
          align={photoSrc === null ? 'center' : 'left'}
          type="serif"
          size={photoSrc == null ? 'l' : 'm'}
          color="on-surface-primary"
        >
          {question}
        </RMText>
      </Question>
      {Footer}
    </StyledRecordingPrompt>
  );
}
