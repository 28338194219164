import { RMText } from '@/components/RMText/RMText';

interface RecordingDurationProps {
  currentDuration: string;
  maxDuration: string;
}

export function RecordingDuration({ currentDuration, maxDuration }: RecordingDurationProps) {
  return (
    <RMText type="sans" size="m" color="on-surface-tertiary" bold>
      {currentDuration} / {maxDuration}
    </RMText>
  );
}
