import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

export const BannerWrapper = styled(motion.div)``;

export const BannerBackground = styled(motion.div)`
  position: fixed;
  left: 0;
  width: 100dvw;
  background-color: var(--color-red-80);
`;

export const Banner = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: calc(100% + calc(var(--spacing-md) * 2));
  background-color: var(--color-red-80);

  body[data-mobile='false'] & {
    padding: 0 var(--spacing-md);
  }

  body[data-mobile='true'] & {
    padding: 0 var(--spacing-sm);
    margin: 0 calc(var(--spacing-md) * -1);
  }
`;
