import { ReactNode } from 'react';

import { RecordingLayout } from '../../layouts/RecordingLayout';

import { AbsoluteWidget, ButtonWrapper, Content, RecordingPromptWrapper, WidgetWrapper } from './RecordingStep.styles';

export interface RecordingStepProps {
  Banner?: ReactNode;
  RecordingIndicator: ReactNode;
  RecordingPrompt: ReactNode;
  RecordingWidget: ReactNode;
  RecordingDeviceButton: ReactNode;
}

export function RecordingStep({
  Banner,
  RecordingIndicator,
  RecordingPrompt,
  RecordingWidget,
  RecordingDeviceButton,
}: RecordingStepProps) {
  return (
    <RecordingLayout.Root
      Header={
        <>
          {Banner}
          <RecordingLayout.Header>
            {RecordingIndicator}
            <ButtonWrapper>{RecordingDeviceButton}</ButtonWrapper>
          </RecordingLayout.Header>
        </>
      }
    >
      <Content>
        <RecordingPromptWrapper>{RecordingPrompt}</RecordingPromptWrapper>
        <WidgetWrapper>
          <AbsoluteWidget>{RecordingWidget}</AbsoluteWidget>
        </WidgetWrapper>
      </Content>
    </RecordingLayout.Root>
  );
}
