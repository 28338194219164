import { useCallback, useEffect, useMemo, useState } from 'react';
import { notNull } from '@remento/utils/array/notNull';
import { findLast } from '@remento/utils/polyfill/find-last';

import { useServices } from '@/Services';

import { INTERVIEW_SESSION_EXPIRATION_TIME_MS, InterviewSession } from '../interview-session';
import { RecorderMediaStream } from '../media-stream/media-stream';
import { RecorderMediaStreamStateType } from '../media-stream/media-stream.types';
import { useProgress } from '../progress/progress.hooks';
import { Progress } from '../progress/progress.types';
import { QueueSynchronizerStateType } from '../queue-synchronizer/queue-synchronizer.types';
import { useStateSlice, useStateType } from '../state-machine.hooks';
import { matchState } from '../state-matcher';

import { RecordingInterruptedError } from './interview-manager.errors';
import { useInterviewManager } from './interview-manager.provider';
import {
  InterviewManagerRecording,
  InterviewManagerState,
  InterviewManagerStateType,
  MINIMUM_RECORDING_DURATION,
} from './interview-manager.types';

export function useInterviewManagerStateType(): InterviewManagerStateType {
  const interviewManager = useInterviewManager();
  return useStateType(interviewManager);
}

export function useIsRecording(): boolean {
  const type = useInterviewManagerStateType();
  return type === InterviewManagerStateType.Recording;
}

export function useIsPausing(): boolean {
  const type = useInterviewManagerStateType();
  return type === InterviewManagerStateType.Pausing;
}

export function useIsPaused(): boolean {
  const type = useInterviewManagerStateType();
  return type === InterviewManagerStateType.Paused;
}

export function useIsFinishing(): boolean {
  const type = useInterviewManagerStateType();
  return type === InterviewManagerStateType.Finishing;
}

export function useIsFinished(): boolean {
  const type = useInterviewManagerStateType();
  return type === InterviewManagerStateType.Finished;
}

export function useInterviewError(): unknown | null {
  const interviewManager = useInterviewManager();
  return useStateSlice(
    interviewManager,
    useCallback((state) => {
      if (state.type === InterviewManagerStateType.UnrecoverableError) {
        return state.error;
      }
      return null;
    }, []),
  );
}

export function useIsInitialized(): boolean {
  const type = useInterviewManagerStateType();
  return type !== InterviewManagerStateType.Empty;
}

export function useUploadProgress(): Progress {
  const interviewManager = useInterviewManager();
  return useProgress(interviewManager.queueSynchronizer.progress);
}

export function useSessionId(): string | null {
  const interviewManager = useInterviewManager();
  return useStateSlice(
    interviewManager,
    useCallback((state) => {
      if (state.type !== InterviewManagerStateType.Empty) {
        return state.sessionId ?? null;
      }
      return null;
    }, []),
  );
}

export function useMediaStream(): RecorderMediaStream {
  const interviewManager = useInterviewManager();
  return interviewManager.mediaStream;
}

export function useRawMediaStream(): MediaStream | null {
  const mediaStream = useMediaStream();
  return useStateSlice(
    mediaStream,
    useCallback((state) => {
      if (state.type === RecorderMediaStreamStateType.Streaming) {
        return state.stream;
      }
      return null;
    }, []),
  );
}

export function useObserveOutOfStorage(callback: () => void): void {
  const interviewManager = useInterviewManager();

  useEffect(() => {
    return interviewManager.subscribeType(
      InterviewManagerStateType.Paused,
      () => {
        const isQueueFull = matchState(interviewManager.queueSynchronizer.getState())
          .withType(QueueSynchronizerStateType.Ready, (state) => state.outOfStorage)
          .withType(QueueSynchronizerStateType.Finishing, (state) => state.outOfStorage)
          .otherwise(() => false);

        if (isQueueFull) {
          callback();
        }
      },
      { immediate: true },
    );
  }, [callback, interviewManager]);
}

export function usePendingInterviewSessions() {
  const { interviewSessionRepository } = useServices();

  const loadPendingSessions = useCallback(() => {
    const ids = interviewSessionRepository.getInterviewSessionIds();
    if (ids.length === 0) {
      return [];
    }

    const sessions = ids
      .map((id) => interviewSessionRepository.getInterviewSession(id))
      .filter(notNull)
      .filter((s) => {
        return s.finished;
      });
    return sessions;
  }, [interviewSessionRepository]);

  const refreshPendingSessions = useCallback(() => {
    const sessions = loadPendingSessions();
    setSessions(sessions);
    return sessions;
  }, [loadPendingSessions]);

  const [pendingSessions, setSessions] = useState<InterviewSession[]>(loadPendingSessions());

  return useMemo(() => ({ refreshPendingSessions, pendingSessions }), [refreshPendingSessions, pendingSessions]);
}

export function useLastInterviewSessionsByPromptId(promptId: string | null) {
  const { interviewSessionRepository } = useServices();

  const loadLastSession = useCallback(() => {
    if (promptId == null) {
      return null;
    }

    const ids = interviewSessionRepository.getInterviewSessionIds();
    if (ids.length === 0) {
      return null;
    }

    const sessions = ids.map((id) => interviewSessionRepository.getInterviewSession(id)).filter(notNull);
    const session = findLast(
      sessions,
      (s) =>
        s.promptId === promptId &&
        s.duration > 0 &&
        s.duration / 1000 - s.promptSentTimestamp > MINIMUM_RECORDING_DURATION &&
        s.nextChunkIndex != null &&
        s.createdAt != null &&
        Date.now() - s.createdAt < INTERVIEW_SESSION_EXPIRATION_TIME_MS,
    );

    return session;
  }, [interviewSessionRepository, promptId]);

  const [lastSession] = useState<InterviewSession | null>(loadLastSession());

  return useMemo(() => ({ lastSession }), [lastSession]);
}

function promptRecordingsSelector(state: InterviewManagerState): InterviewManagerRecording[] {
  if (state.type === InterviewManagerStateType.Paused && state.recordings.length > 0) {
    return state.recordings;
  }
  return [];
}

export function usePromptRecordings(): InterviewManagerRecording[] {
  const interviewManager = useInterviewManager();
  const recordingsData = useStateSlice(interviewManager, promptRecordingsSelector);
  const [recordings, setRecordings] = useState<InterviewManagerRecording[]>(recordingsData);

  useEffect(() => {
    setRecordings(recordingsData);
  }, [recordings.length, recordingsData]);

  return recordings;
}

export function useBlobUrl(blob: Blob | null): string | null {
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!blob) {
      setUrl((previousUrl) => {
        if (previousUrl) {
          URL.revokeObjectURL(previousUrl);
        }
        return null;
      });
      return;
    }

    const newUrl = URL.createObjectURL(blob);
    setUrl((previousUrl) => {
      if (previousUrl) {
        URL.revokeObjectURL(previousUrl);
      }
      return newUrl;
    });
  }, [blob]);

  // Clean up the url when the component unmounts
  useEffect(() => {
    return () => {
      if (url) {
        URL.revokeObjectURL(url);
      }
    };
  }, [url]);

  return url;
}

export function useBlobsUrl(recordings: InterviewManagerRecording[]): string[] | null {
  const [urls, setUrls] = useState<string[] | null>(null);

  useEffect(() => {
    if (!recordings || recordings.length === 0) {
      setUrls((previousUrls) => {
        if (previousUrls) {
          previousUrls.forEach((url) => {
            URL.revokeObjectURL(url);
          });
        }
        return null;
      });
      return;
    }

    setUrls((previousUrls) => {
      if (previousUrls) {
        previousUrls.forEach((url) => {
          URL.revokeObjectURL(url);
        });
      }

      return recordings.map(({ recording }) => {
        return URL.createObjectURL(recording);
      });
    });
  }, [recordings]);

  // Clean up the url when the component unmounts
  useEffect(() => {
    return () => {
      if (urls) {
        urls.forEach((url) => {
          URL.revokeObjectURL(url);
        });
      }
    };
  }, [urls]);

  return urls;
}

export function useObserveRecordingInterruptedError(callback: (error: RecordingInterruptedError) => void): void {
  const interviewManager = useInterviewManager();

  useEffect(() => {
    return interviewManager.subscribeType(
      InterviewManagerStateType.UnrecoverableError,
      ({ error }) => {
        if (error instanceof RecordingInterruptedError) {
          callback(error);
        }
      },
      { immediate: true },
    );
  }, [callback, interviewManager]);
}
