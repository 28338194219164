import { useCallback, useRef, useState } from 'react';
import { faRecordVinyl } from '@fortawesome/pro-regular-svg-icons';
import { faPause, faPlay } from '@fortawesome/pro-solid-svg-icons';
import { AnimatePresence } from 'framer-motion';
import lottie, { AnimationItem } from 'lottie-web';

import ArrowDownLottieAnimation from '@/@assets/arrow-down-lottie-animation.json';
import { RMButton } from '@/components/RMButton/RMButton';
import { InterviewManagerRecording, RecordingType } from '@/modules/conversation-recorder/interview';

import { RecordingCamera } from '../RecordingCamera/RecordingCamera';
import { RecordingConfirmDialog } from '../RecordingConfirmDialog/RecordingConfirmDialog';
import { RecorderCountdown } from '../RecordingCountdown/RecordingCountdown';
import { RecordingPlayback } from '../RecordingPlayback';

import {
  Container,
  Controls,
  LottieWrapper,
  Pip,
  PipPlaceholder,
  PlaybackContent,
  PlaybackWrapper,
  Row,
} from './RecordingWidget.styles';

const pipSize = 80;

export type RecordingWidgetAction =
  | 'start-recording'
  | 'stop-recording'
  | 'end-recording'
  | 'rewind-prompt'
  | 'confirm-recording'
  | 'close-confirm';

export interface RecordingWidgetReadyState {
  type: 'ready';
  isFirstTake: boolean;
}

export interface RecordingWidgetPausedState {
  type: 'paused';
  isRecordingTooShort: boolean;
  canContinueRecording: boolean;
}

export interface RecordingWidgetRecordingState {
  type: 'recording';
}

export interface RecordingWidgetConfirmState {
  type: 'confirm';
  canContinueRecording: boolean;
  isRetakeConfirmationOpen: boolean;
}

export interface RecordingWidgetFinishedState {
  type: 'finished';
}

export type RecordingWidgetState =
  | RecordingWidgetReadyState
  | RecordingWidgetPausedState
  | RecordingWidgetRecordingState
  | RecordingWidgetFinishedState
  | RecordingWidgetConfirmState;

export interface RecordingWidgetProps {
  state: RecordingWidgetState;
  mediaStream: MediaStream | null;
  recordings: InterviewManagerRecording[];
  recordingType: RecordingType;
  disabled?: boolean;
  onAction: (action: RecordingWidgetAction) => void;
}

export function RecordingWidget({
  state,
  mediaStream,
  recordings,
  recordingType,
  disabled = false,
  onAction,
}: RecordingWidgetProps) {
  // Countdown
  const [countdownVisible, setCountdownVisible] = useState(false);

  const [isEnding, setIsEnding] = useState(false);

  const handleStartCountdown = () => {
    setCountdownVisible(true);
  };

  const handleStartRecording = () => {
    setCountdownVisible(false);
    onAction('start-recording');
  };

  const handleStopRecording = async () => {
    await onAction('stop-recording');
  };

  // Lottie animation
  const animationRef = useRef<AnimationItem | null>(null);
  const lottieRef = useCallback((node: HTMLDivElement | null) => {
    if (node === null) {
      if (animationRef.current) {
        animationRef.current.destroy();
        animationRef.current = null;
      }
      return;
    }

    const animation = lottie.loadAnimation({
      container: node,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: ArrowDownLottieAnimation,
    });
    animationRef.current = animation;
  }, []);

  return (
    <RecordingPlayback.Root
      recordings={state.type === 'confirm' ? recordings : []}
      // To show the video thumbnail, we need to at least load the first frame.
      initialTime={recordingType === 'video' ? 0.5 : undefined}
      stream={false}
    >
      <Container disabled={disabled}>
        <AnimatePresence>
          {state.type === 'paused' && !disabled && (
            <Row
              initial={{ translateY: pipSize, opacity: 0.75 }}
              animate={{ translateY: 0, opacity: 1 }}
              exit={{ translateY: pipSize, opacity: 0.75 }}
              transition={{ stiffness: 150 }}
            >
              <RMButton
                size="massive"
                fullWidth
                disabled={isEnding || !state.canContinueRecording}
                leftIcon={faPlay}
                autoLoading
                onClick={() => onAction('start-recording')}
              >
                Resume
              </RMButton>

              {state.isRecordingTooShort === false && (
                <RMButton
                  size="massive"
                  background="primary"
                  fullWidth
                  autoLoading
                  onClick={async () => {
                    onAction('confirm-recording');
                  }}
                >
                  Done
                </RMButton>
              )}
            </Row>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {state.type !== 'paused' && (
            <Row
              initial={{ translateY: pipSize, opacity: 0.75 }}
              animate={{ translateY: 0, opacity: 1 }}
              exit={{ translateY: pipSize, opacity: 0.75 }}
              transition={{ stiffness: 150 }}
            >
              {recordingType === 'video' && <PipPlaceholder />}

              {state.type === 'ready' && countdownVisible === false && (
                <>
                  <RMButton
                    size="massive"
                    background="affirmative"
                    leftIcon={state.type === 'ready' ? faRecordVinyl : null}
                    fullWidth
                    onClick={handleStartCountdown}
                  >
                    Start recording
                  </RMButton>
                  {state.type === 'ready' && <LottieWrapper data-video={recordingType === 'video'} ref={lottieRef} />}
                </>
              )}

              {state.type === 'recording' && (
                <RMButton size="massive" fullWidth autoLoading leftIcon={faPause} onClick={handleStopRecording}>
                  Pause
                </RMButton>
              )}
            </Row>
          )}
        </AnimatePresence>

        <RecordingConfirmDialog
          open={state.type === 'confirm' && !disabled}
          onConfirm={async () => {
            try {
              setIsEnding(true);
              await onAction('end-recording');
            } finally {
              setIsEnding(false);
            }
          }}
          onRetake={() => {
            onAction('rewind-prompt');
          }}
          onClose={() => {
            onAction('close-confirm');
          }}
        >
          {recordings.length > 0 && (
            <PlaybackContent data-type={recordingType}>
              <PlaybackWrapper hidden={recordingType === 'audio'}>
                <RecordingPlayback.Video />
              </PlaybackWrapper>
              <Controls>
                <RecordingPlayback.Controls />
              </Controls>
            </PlaybackContent>
          )}
        </RecordingConfirmDialog>

        {recordingType === 'video' && state.type !== 'paused' && state.type !== 'confirm' && (
          <Pip>
            <div>
              <RecordingCamera mediaStream={mediaStream} />
            </div>
          </Pip>
        )}

        {recordingType === 'audio' && (
          <div hidden>
            <RecordingPlayback.Video />
          </div>
        )}

        {countdownVisible && <RecorderCountdown onStartRecording={handleStartRecording} />}
      </Container>
    </RecordingPlayback.Root>
  );
}
