import { useMemo, useState } from 'react';

import {
  useInterviewManager,
  useIsPaused,
  useIsPausing,
  useIsRecording,
} from '@/modules/conversation-recorder/interview';
import { useInterviewManagerTicker } from '@/modules/conversation-recorder/interview/interview-manager-ticker';

import { RecordingBanner } from '../components/RecordingBanner/RecordingBanner';

interface RecordingBannerContainerProps {
  hideBanner: boolean;
  onRecordTimeout: () => void;
}

const BANNER_OPEN_THRESHOLD = 2 * 60;

export function RecordingBannerContainer({ hideBanner, onRecordTimeout }: RecordingBannerContainerProps) {
  const interviewManager = useInterviewManager();
  const [remainingTime, setRemainingTime] = useState(0);
  const isRecording = useIsRecording();
  const isPaused = useIsPaused();
  const isPausing = useIsPausing();

  useInterviewManagerTicker(() => {
    try {
      const remainingTime = interviewManager.getRemainingRecordingDuration();
      if (remainingTime <= 0) {
        onRecordTimeout();
      } else {
        setRemainingTime(remainingTime);
      }
    } catch {
      // interview manager died
    }
  });

  const formatRemainingTime = useMemo(() => {
    const seconds = Math.round(remainingTime) % 60;
    const minutes = Math.floor((Math.round(remainingTime) % 3600) / 60);

    if (minutes > 0 && seconds > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} and ${seconds} seconds`;
    }
    if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
    return `${seconds} seconds`;
  }, [remainingTime]);

  return (
    <RecordingBanner
      open={(isRecording || isPausing || isPaused) && !hideBanner && remainingTime < BANNER_OPEN_THRESHOLD}
      message={`${formatRemainingTime} remaining in this session`}
    />
  );
}
