import { ReactNode } from 'react';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RMText } from '@/components/RMText/RMText';

import { Badge, BadgeStatus, StyledRecordingIndicator } from './RecordingIndicator.styles';

export interface RecordingIndicatorProps {
  state: 'recording' | 'finished' | 'ready' | 'paused' | 'confirm';
  Waveform: ReactNode;
  RecordingDuration: ReactNode;
}

export function RecordingIndicator({ state, Waveform, RecordingDuration }: RecordingIndicatorProps) {
  return (
    <StyledRecordingIndicator>
      {RecordingDuration}

      <Badge>
        {Waveform}
        <BadgeStatus>
          {state === 'recording' && (
            <>
              <FontAwesomeIcon size="sm" icon={faCircle} className="animate-pulse" />
              <RMText type="serif" size="s2" color="recording" bold>
                REC
              </RMText>
            </>
          )}
          {state !== 'recording' && (
            <RMText type="serif" size="s2" color="on-surface-tertiary" bold>
              PAUSED
            </RMText>
          )}
        </BadgeStatus>
      </Badge>
    </StyledRecordingIndicator>
  );
}
